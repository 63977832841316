import React, { useCallback, useEffect, useState } from 'react'
import { CloseOutlined, MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { Row, Form, Space, Input, Button, Card, Typography, Spin } from 'antd'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { getDomain } from '../utils'
import { useApiData } from '../context/apiContext'

const { Link } = Typography
const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />

const LOGIN_ERROR = 'Invalid email address or password.'
const WRONG_PASSWORD_ERROR = 'Incorrect password. Please try again.'

const Login = ({ loginClass, closeLoginModal, message, selectedState, showSpinner, setShowSpinner, handleSubmit }) => {
  const router = useRouter()
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loginMessage, setLoginMessage] = useState('')
  const [websiteMaintenanceMessage, setWebsiteMaintenanceMessage] = useState(null)
  const [websiteMaintenanceStatus, setWebsiteMaintenanceStatus] = useState(false)
  const { apiData1, apiData2 } = useApiData();

  useEffect(() => {
    if (apiData1 && apiData2) {
      function fetchWebsiteMaintainence() {
        var websiteStatusResponse = apiData1
        var websiteMessageResponse = apiData2
  
        const status = websiteStatusResponse?.data;
        if (websiteMaintenanceStatus == null && status?.Result == "Active" && status?.Pages.includes("MyAccountLogin")) {
          setWebsiteMaintenanceStatus(!status.AllowAccessPages.includes("MyAccountLogin"));
          setWebsiteMaintenanceMessage(websiteMessageResponse?.data?.Result);
        } else if (websiteMessageResponse?.data && !websiteMaintenanceMessage) {
          setWebsiteMaintenanceStatus(false);
          setWebsiteMaintenanceMessage(null);
        }
      }
      fetchWebsiteMaintainence()
    }
  }, [apiData1, apiData2]);

  useEffect(() => {
    if (message) {
      if (message === 'Not Found') {
        setErrorMessage(LOGIN_ERROR)
        setLoginMessage('')
      } else if (message === "Incorrect Password") {
        setErrorMessage(WRONG_PASSWORD_ERROR)
        setLoginMessage('')
      }
      else if (message === "Non Converted GW User" && !!emailAddress) {
        router.push(`/my-account/update?verifiedUser=${emailAddress}`)
      }
      else if (message === "Policy User and Non Converted GW User") {
        router.push('/my-account/forgot-email')
      }
      else {
        setLoginMessage(message)
        setErrorMessage('')
      }
    }
  }, [message])

  const resetState = useCallback(() => {
    setEmailAddress('')
    setPassword('')
    setLoginMessage('')
    setErrorMessage('')
    setShowSpinner(false)
  }, [setShowSpinner])

  useEffect(() => {
    if (selectedState) {
      resetState()
    }
  }, [resetState, selectedState])

  const handleClick = () => {
    setLoginMessage('')
    setErrorMessage('')
    setShowSpinner(true)
    const response = validateEmail()
    if (!response) {
      handleSubmit(emailAddress, password)
    }
  }

  const validateEmail = () => {
    const regEx = /\S+@\S+\.\S+/
    setLoginMessage('')
    setErrorMessage('')
    let validationResponse = null
    if (emailAddress.length === 0) {
      validationResponse = LOGIN_ERROR
    } else {
      if (emailAddress.length > 0 && regEx.test(emailAddress)) {
        validationResponse = null
      } else {
        validationResponse = emailAddress.length > 0 && LOGIN_ERROR
      }
    }
    setErrorMessage(validationResponse)
    if (!validationResponse) {
      if (password.length === 0) {
        validationResponse = LOGIN_ERROR
      }
      setErrorMessage(validationResponse)
    }
    return validationResponse
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
        handleClick()
    }
  }

  return (
    <Card
      bordered={false}
      className={loginClass}
      extra={
        <a
          className="signin-close"
          onClick={() => {
            resetState()
            closeLoginModal()
          }}
        >
          <CloseOutlined />
        </a>
      }
      title={`${selectedState} Policyholder Log In`}
    >
      <Row className="create-account-text">
        <a className="forgot-text sub-title subtext mobile-margin-right-0">
          Not registered?{' '}
          <Link
            className="bold-text"
            disabled={websiteMaintenanceStatus}
            href={`/my-account/register`}
          >
            Register Now!
          </Link>
        </a>
      </Row>
      {websiteMaintenanceMessage && (
        <Row>
          <div className="blue-message float-left blue-message-bold" style={{ marginTop: 0 }}>
            {websiteMaintenanceMessage}
          </div>
        </Row>
      )}
      <Form>
        <Form.Item>
          <Input
            autoComplete="off"
            className="input-text"
            disabled={websiteMaintenanceStatus}
            onChange={event => setEmailAddress(event.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Email Address"
            prefix={<MailOutlined className="site-form-item-icon" />}
            value={emailAddress}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            autoComplete="off"
            className="input-text"
            disabled={websiteMaintenanceStatus}
            iconRender={visible => (visible ? 'Hide' : 'Show')}
            onChange={event => setPassword(event.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            value={password}
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {loginMessage === 'Inactive' && (
            <div className="blue-message">
              Your account is inactive. Please call 800-535-2873 for assistance. If you would like to make a
              payment,&nbsp;
              <a href={"https://" + getDomain("payments")} rel="noopener noreferrer" target="_blank">
                click here
              </a>
            </div>
          )}
          {loginMessage !== 'Inactive' && errorMessage !== 'Invalid email address or password.' && showSpinner && (
            <div className="blue-message float-left">Please wait ...</div>
          )}
          {loginMessage !== 'Inactive' && errorMessage !== 'Invalid email address or password.' && showSpinner && (
            <Spin indicator={antIcon} />
          )}
        </Form.Item>
        {!websiteMaintenanceStatus && (
          <Row className="forgot-links">
            <a className="forgot-text">Forgot</a>
            <Link href="/my-account/forgot-email" style={{ marginRight: 3 }}>
              {' '}
              Username (Email)
            </Link>
            <a className="forgot-text">or</a>
            <Link href="/my-account/forgot-password" style={{ marginRight: 3 }}>
              Password?
            </Link>
          </Row>
        )}
        <Form.Item className="btn-control">
          <Space>
            <Button
              className="login login-form-button"
              disabled={websiteMaintenanceStatus && !validateEmail()}
              onClick={handleClick}
            >
              Log In
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

Login.propTypes = {
  message: PropTypes.string,
  loginClass: PropTypes.string,
  closeLoginModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedState: PropTypes.string,
  showSpinner: PropTypes.bool,
  setShowSpinner: PropTypes.func
}

export default Login
