import Head from 'next/head'

const Metadata = () => {
  return (
    <Head>
      {/* <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="expires" content="0" />
      <meta name="facebook-domain-verification" content="4vcf08vbnbedkcvmjpvkzr79tarnn7" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=Birthstone+Bounce&family=Oswald:wght@200;400&display=swap" rel="stylesheet" />
      <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      <link rel="stylesheet" href="https://use.typekit.net/vkb3wbs.css" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js" />
      <script async="" src="https://www.googletagmanager.com/gtm.js?id=GTM-P23JFSQ" />
      <script type="text/javascript" async="" src="https://www.googletagmanager.com/gtag/js?id=G-VL9ZRJR0WC&amp;l=dataLayer&amp;cx=c" />
      <script async="" src="https://www.googletagmanager.com/gtag/js?id=UA-177506-6" />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P23JFSQ');
          `,
        }}
      ></script> */}
      {/* <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P23JFSQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="expires" content="0" />
      <meta name="facebook-domain-verification" content="4vcf08vbnbedkcvmjpvkzr79tarnn7" />
      <meta name="robots" content="noindex"></meta>
      <meta name="google-site-verification" content="z6rSevZR1gRqH8wZyEipBVMIypa1qd5JpxnhJecZXRw" />
    </Head>
  )
}

export default Metadata
